import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export type PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "About",
      "Comparison",
      "ComponentDataEntry",
      "ComponentDataSubscription",
      "ComponentUiCard",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiLink",
      "ComponentUiSection",
      "ComponentUiTab",
      "ComponentUiValue",
      "Demo",
      "Feature",
      "Home",
      "Layout",
      "Member",
      "Pricing",
      "ReactIconsIconlibrary",
      "Subscriber",
      "Testimonial",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "About",
      "Comparison",
      "ComponentDataEntry",
      "ComponentDataSubscription",
      "ComponentUiCard",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiLink",
      "ComponentUiSection",
      "ComponentUiTab",
      "ComponentUiValue",
      "Demo",
      "Feature",
      "Home",
      "Layout",
      "Member",
      "Pricing",
      "ReactIconsIconlibrary",
      "Subscriber",
      "Testimonial",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser"
    ]
  }
};
      export default result;
    
export const EntryFragmentDoc = gql`
    fragment Entry on ComponentDataEntry {
  id
  key
  value
}
    `;
export const HeadlineFragmentDoc = gql`
    fragment Headline on ComponentUiHeadline {
  id
  title
  subtitle
}
    `;
export const LinkFragmentDoc = gql`
    fragment Link on ComponentUiLink {
  id
  title
  url
  target
}
    `;
export const MemberFragmentDoc = gql`
    fragment Member on Member {
  role
  fullName
  avatar {
    data {
      attributes {
        url
        alternativeText
      }
    }
  }
}
    `;
export const FileFragmentDoc = gql`
    fragment File on UploadFileEntity {
  id
  attributes {
    previewUrl
    alternativeText
    url
  }
}
    `;
export const CardFragmentDoc = gql`
    fragment Card on ComponentUiCard {
  id
  title
  subtitle
  description
  media {
    data {
      ...File
    }
  }
}
    `;
export const SectionFragmentDoc = gql`
    fragment Section on ComponentUiSection {
  visible
  heading {
    ...Card
  }
}
    `;
export const SubscriberFragmentDoc = gql`
    fragment Subscriber on SubscriberEntity {
  attributes {
    created
    email
  }
}
    `;
export const SubscriptionFragmentDoc = gql`
    fragment Subscription on ComponentDataSubscription {
  id
  type
  price
  montly
  priceFull
  popular
  description
  list
  button
}
    `;
export const TabFragmentDoc = gql`
    fragment Tab on ComponentUiTab {
  id
  name
  pane {
    ...Card
  }
}
    `;
export const ValueFragmentDoc = gql`
    fragment Value on ComponentUiValue {
  id
  title
  subtitle
  description
  visible
  media {
    data {
      attributes {
        url
        alternativeText
      }
    }
  }
  background
  reversed
}
    `;
export const CreateSubscriberDocument = gql`
    mutation createSubscriber($data: SubscriberInput!) {
  createSubscriber(data: $data) {
    data {
      ...Subscriber
    }
  }
}
    ${SubscriberFragmentDoc}`;
export type CreateSubscriberMutationFn = Apollo.MutationFunction<CreateSubscriberMutation, CreateSubscriberMutationVariables>;
export function useCreateSubscriberMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubscriberMutation, CreateSubscriberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSubscriberMutation, CreateSubscriberMutationVariables>(CreateSubscriberDocument, options);
      }
export type CreateSubscriberMutationHookResult = ReturnType<typeof useCreateSubscriberMutation>;
export type CreateSubscriberMutationResult = Apollo.MutationResult<CreateSubscriberMutation>;
export const AboutDocument = gql`
    query about {
  about {
    data {
      attributes {
        heroAbout {
          ...Section
        }
        heroImage {
          data {
            attributes {
              url
              alternativeText
            }
          }
        }
        subscribeAbout {
          ...Section
        }
        members {
          data {
            attributes {
              ...Member
            }
          }
        }
        story {
          ...Card
        }
        general {
          ...Card
        }
      }
    }
  }
}
    ${SectionFragmentDoc}
${CardFragmentDoc}
${FileFragmentDoc}
${MemberFragmentDoc}`;
export function useAboutQuery(baseOptions?: Apollo.QueryHookOptions<AboutQuery, AboutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AboutQuery, AboutQueryVariables>(AboutDocument, options);
      }
export function useAboutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AboutQuery, AboutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AboutQuery, AboutQueryVariables>(AboutDocument, options);
        }
export type AboutQueryHookResult = ReturnType<typeof useAboutQuery>;
export type AboutLazyQueryHookResult = ReturnType<typeof useAboutLazyQuery>;
export type AboutQueryResult = Apollo.QueryResult<AboutQuery, AboutQueryVariables>;
export const ComparisonDocument = gql`
    query comparison($key: StringFilterInput!) {
  comparisons(filters: {slug: $key}) {
    data {
      attributes {
        slug
        title
      }
    }
  }
}
    `;
export function useComparisonQuery(baseOptions: Apollo.QueryHookOptions<ComparisonQuery, ComparisonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ComparisonQuery, ComparisonQueryVariables>(ComparisonDocument, options);
      }
export function useComparisonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ComparisonQuery, ComparisonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ComparisonQuery, ComparisonQueryVariables>(ComparisonDocument, options);
        }
export type ComparisonQueryHookResult = ReturnType<typeof useComparisonQuery>;
export type ComparisonLazyQueryHookResult = ReturnType<typeof useComparisonLazyQuery>;
export type ComparisonQueryResult = Apollo.QueryResult<ComparisonQuery, ComparisonQueryVariables>;
export const DemoDocument = gql`
    query demo {
  demo {
    data {
      attributes {
        heroDemo {
          ...Headline
        }
        videoURL
      }
    }
  }
}
    ${HeadlineFragmentDoc}`;
export function useDemoQuery(baseOptions?: Apollo.QueryHookOptions<DemoQuery, DemoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DemoQuery, DemoQueryVariables>(DemoDocument, options);
      }
export function useDemoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DemoQuery, DemoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DemoQuery, DemoQueryVariables>(DemoDocument, options);
        }
export type DemoQueryHookResult = ReturnType<typeof useDemoQuery>;
export type DemoLazyQueryHookResult = ReturnType<typeof useDemoLazyQuery>;
export type DemoQueryResult = Apollo.QueryResult<DemoQuery, DemoQueryVariables>;
export const FeatureDocument = gql`
    query feature($key: StringFilterInput!) {
  features(filters: {key: $key}) {
    data {
      attributes {
        key
        top {
          ...Value
        }
        subscribeInitial {
          ...Section
        }
        reviews {
          ...Card
        }
        middle {
          ...Value
        }
        reviewsSecondary {
          ...Card
        }
        subscribe {
          ...Section
        }
        bottom {
          ...Value
        }
      }
    }
  }
}
    ${ValueFragmentDoc}
${SectionFragmentDoc}
${CardFragmentDoc}
${FileFragmentDoc}`;
export function useFeatureQuery(baseOptions: Apollo.QueryHookOptions<FeatureQuery, FeatureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeatureQuery, FeatureQueryVariables>(FeatureDocument, options);
      }
export function useFeatureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeatureQuery, FeatureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeatureQuery, FeatureQueryVariables>(FeatureDocument, options);
        }
export type FeatureQueryHookResult = ReturnType<typeof useFeatureQuery>;
export type FeatureLazyQueryHookResult = ReturnType<typeof useFeatureLazyQuery>;
export type FeatureQueryResult = Apollo.QueryResult<FeatureQuery, FeatureQueryVariables>;
export const HomeDocument = gql`
    query home {
  home {
    data {
      attributes {
        hero {
          ...Section
        }
        subtitleHero {
          ...Headline
        }
        subsection {
          ...Section
        }
        features {
          ...Tab
        }
        subsection_2 {
          ...Section
        }
        support {
          ...Card
        }
        solutions {
          ...Tab
        }
        subscribe {
          ...Section
        }
        partners {
          ...Card
        }
        testimonials {
          ...Card
        }
      }
    }
  }
}
    ${SectionFragmentDoc}
${CardFragmentDoc}
${FileFragmentDoc}
${HeadlineFragmentDoc}
${TabFragmentDoc}`;
export function useHomeQuery(baseOptions?: Apollo.QueryHookOptions<HomeQuery, HomeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
      }
export function useHomeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeQuery, HomeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
        }
export type HomeQueryHookResult = ReturnType<typeof useHomeQuery>;
export type HomeLazyQueryHookResult = ReturnType<typeof useHomeLazyQuery>;
export type HomeQueryResult = Apollo.QueryResult<HomeQuery, HomeQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    id
  }
}
    `;
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const MembersDocument = gql`
    query members {
  members(pagination: {limit: 20}) {
    data {
      attributes {
        ...Member
      }
    }
  }
}
    ${MemberFragmentDoc}`;
export function useMembersQuery(baseOptions?: Apollo.QueryHookOptions<MembersQuery, MembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MembersQuery, MembersQueryVariables>(MembersDocument, options);
      }
export function useMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MembersQuery, MembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MembersQuery, MembersQueryVariables>(MembersDocument, options);
        }
export type MembersQueryHookResult = ReturnType<typeof useMembersQuery>;
export type MembersLazyQueryHookResult = ReturnType<typeof useMembersLazyQuery>;
export type MembersQueryResult = Apollo.QueryResult<MembersQuery, MembersQueryVariables>;
export const PricingDocument = gql`
    query pricing {
  pricing {
    data {
      attributes {
        heroPrice {
          ...Section
        }
        plans {
          ...Subscription
        }
        subtitle {
          ...Headline
        }
        reviews {
          ...Card
        }
        faq {
          ...Headline
        }
      }
    }
  }
}
    ${SectionFragmentDoc}
${CardFragmentDoc}
${FileFragmentDoc}
${SubscriptionFragmentDoc}
${HeadlineFragmentDoc}`;
export function usePricingQuery(baseOptions?: Apollo.QueryHookOptions<PricingQuery, PricingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PricingQuery, PricingQueryVariables>(PricingDocument, options);
      }
export function usePricingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PricingQuery, PricingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PricingQuery, PricingQueryVariables>(PricingDocument, options);
        }
export type PricingQueryHookResult = ReturnType<typeof usePricingQuery>;
export type PricingLazyQueryHookResult = ReturnType<typeof usePricingLazyQuery>;
export type PricingQueryResult = Apollo.QueryResult<PricingQuery, PricingQueryVariables>;
export const TestimonialsDocument = gql`
    query testimonials {
  testimonials {
    data {
      attributes {
        content {
          ...Card
        }
      }
    }
  }
}
    ${CardFragmentDoc}
${FileFragmentDoc}`;
export function useTestimonialsQuery(baseOptions?: Apollo.QueryHookOptions<TestimonialsQuery, TestimonialsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TestimonialsQuery, TestimonialsQueryVariables>(TestimonialsDocument, options);
      }
export function useTestimonialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TestimonialsQuery, TestimonialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TestimonialsQuery, TestimonialsQueryVariables>(TestimonialsDocument, options);
        }
export type TestimonialsQueryHookResult = ReturnType<typeof useTestimonialsQuery>;
export type TestimonialsLazyQueryHookResult = ReturnType<typeof useTestimonialsLazyQuery>;
export type TestimonialsQueryResult = Apollo.QueryResult<TestimonialsQuery, TestimonialsQueryVariables>;