import { Button, Drawer } from "antd"
import { FC } from "react"

import { useApp } from "../app"
import { generalNavigation } from "../menu"
import { SvgIcon } from "../ui"
import "./Menu.less"
import Navigation from "./Navigation"
const BurgerMenu: FC = () => {
  const { burger } = useApp()
  return (
    <>
      <Drawer
        headerStyle={{ padding: 0, borderBottom: "none" }}
        className={"mobile-menu"}
        width={"100%"}
        height={"100%"}
        onClose={burger.toggle}
        open={burger.opened}
      >
        <Navigation items={generalNavigation} className={"mobile-navigation"} mode={"inline"} onClick={burger.toggle} />
        <div className={"mobile-menu-footer"}>
          <Button href={"https://app.inveloapp.com/login"} className={"primary purple"}>
            {"Log In"}
          </Button>
          <Button href={"https://app.inveloapp.com/register/"} className={"primary white"}>
            {"Sign Up"}
          </Button>
        </div>
      </Drawer>
      <SvgIcon className={"burger-icon"} type={"burgerIcon"} onClick={() => burger.toggle()} />
    </>
  )
}

export { BurgerMenu }
