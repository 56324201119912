import { Row } from "antd"
import { FC } from "react"

import { useMediaQuery } from "react-responsive"
import { NavLink } from "react-router-dom"
import { useHeaderPosition } from "../../helpers/useHeaderPosition"
import { Logo } from "../logo"
import { HeaderMenu } from "../menu"
import { BurgerMenu } from "../menu/BurgerMenu"

const Header: FC<{ renderMenu?: FC }> = ({ renderMenu: HorizontalMenu = HeaderMenu }) => {
  const isMobileOrTablet = useMediaQuery({ maxWidth: 991 })

  useHeaderPosition()
  return (
    <Row align={"middle"} justify={isMobileOrTablet ? "end" : "center"}>
      <Logo fill={"white"} />
      {isMobileOrTablet ? (
        <BurgerMenu />
      ) : (
        <>
          <HorizontalMenu />
          <Row className={"control-panel"}>
            <NavLink to={"https://app.inveloapp.com/register"} className={"primary transparent"}>
              {"Sign Up"}
            </NavLink>
            <NavLink to={"https://app.inveloapp.com/login"} className={"primary white"}>
              {"Log In"}
            </NavLink>
          </Row>
        </>
      )}
    </Row>
  )
}

export { Header }
