import { FC, SVGProps } from "react"

import { ReactComponent as StarIcon } from "./image/achieve-icon.svg"
import { ReactComponent as AlignIcon } from "./image/align-icon.svg"
import { ReactComponent as ArrowChart } from "./image/arrow-chart.svg"
import { ReactComponent as Arrow } from "./image/arrow.svg"
import { ReactComponent as AskIcon } from "./image/ask-icon.svg"
import { ReactComponent as BurgerIcon } from "./image/burger-icon.svg"
import { ReactComponent as CarouselArrow } from "./image/carousel-arrow.svg"
import { ReactComponent as ChartCircle } from "./image/chart-circle.svg"
import { ReactComponent as ChartSquare } from "./image/chart-square.svg"
import { ReactComponent as CheckTable } from "./image/check-table.svg"
import { ReactComponent as Clipboard } from "./image/clipboard.svg"
import { ReactComponent as CrossPen } from "./image/cross-pen.svg"
import { ReactComponent as CrossTable } from "./image/cross-table.svg"
import { ReactComponent as Cross } from "./image/cross.svg"
import { ReactComponent as Database } from "./image/database.svg"
import { ReactComponent as FacebookSocial } from "./image/facebook-social.svg"
import { ReactComponent as Frame } from "./image/frame.svg"
import { ReactComponent as HeartIcon } from "./image/heart-icon.svg"
import { ReactComponent as IllutstrationTestimonials } from "./image/illustration.svg"
import { ReactComponent as InstaSocial } from "./image/inst-social.svg"
import { ReactComponent as LinkedinSocial } from "./image/linkedin-social.svg"
import { ReactComponent as LogoMain } from "./image/logo-main.svg"
import { ReactComponent as MessagesIcon } from "./image/messages-icon.svg"
import { ReactComponent as MonitorIcon } from "./image/monitor-icon.svg"
import { ReactComponent as PlayIcon } from "./image/play-icon.svg"
import { ReactComponent as Plus } from "./image/plus.svg"
import { ReactComponent as Sidebar } from "./image/sidebar.svg"
import { ReactComponent as StudentHat } from "./image/student-hat.svg"
import { ReactComponent as Target } from "./image/target.svg"
import { ReactComponent as TwitterSocial } from "./image/twitter-social.svg"
import { ReactComponent as YoutubeSocial } from "./image/youtube-social.svg"

export type IconTypes =
  | "checkTable"
  | "crossTable"
  | "align"
  | "plus"
  | "carouselArrow"
  | "cross"
  | "arrowChart"
  | "target"
  | "database"
  | "chartCircle"
  | "chartSquare"
  | "clipboard"
  | "frame"
  | "sidebar"
  | string
  | "studentHat"
  | "messagesIcon"
  | "monitorIcon"
  | "playIcon"
  | "starIcon"
  | "askIcon"
  | "burgerIcon"
  | "logoMain"
  | "illustartionTestimonials"
  | "instaSocial"
  | "facebookSocial"
  | "twitterSocial"
  | "youtubeSocial"
  | "linkedinSocial"
  | "arrow"
  | "crossPen"
  | "heartIcon"

const icons: {
  [key: string]: FC<SVGProps<SVGSVGElement>>
} = {
  plus: Plus,
  checkTable: CheckTable,
  crossTable: CrossTable,
  align: AlignIcon,
  heartIcon: HeartIcon,
  cross: Cross,
  arrowChart: ArrowChart,
  arrow: Arrow,
  chartCircle: ChartCircle,
  database: Database,
  target: Target,
  chartSquare: ChartSquare,
  clipboard: Clipboard,
  frame: Frame,
  sidebar: Sidebar,
  burgerIcon: BurgerIcon,
  illustartionTestimonials: IllutstrationTestimonials,
  logoMain: LogoMain,
  studentHat: StudentHat,
  messagesIcon: MessagesIcon,
  monitorIcon: MonitorIcon,
  playIcon: PlayIcon,
  carouselArrow: CarouselArrow,
  starIcon: StarIcon,
  askIcon: AskIcon,
  instaSocial: InstaSocial,
  facebookSocial: FacebookSocial,
  twitterSocial: TwitterSocial,
  youtubeSocial: YoutubeSocial,
  linkedinSocial: LinkedinSocial,
  crossPen: CrossPen,
} as const

type SvgIconProps = SVGProps<SVGSVGElement> & { type: IconTypes }

const SvgIcon: FC<SvgIconProps> = ({ type, ...svgProps }) => {
  const Icon = icons[type] ?? null
  return Icon && <Icon {...svgProps} />
}

export { SvgIcon }
