import { List, Row, Space, Tag } from "antd"
import { FC } from "react"
import { NavLink } from "react-router-dom"
import { FooterBar } from "../section"
import { SvgIcon } from "../ui"
import "./Footer.less"

const Footer: FC = () => {
  const gettingStartedLinks = {
    title: "Getting started",
    links: [
      { title: "About Us", link: "/about" },
      { title: "Try for free!", link: "https://app.inveloapp.com/register/" },
      { title: "Pricing", link: "/pricing" },
      { title: "Login", link: "https://app.inveloapp.com/login" },
      { title: "Contact Us", link: "https://inveloapp.com/signup-free" },
    ],
  }

  const featuresLinks = {
    title: "Features",
    links: [
      { title: "List Builder", link: `/feature/list-builder` },
      { title: "CRM", link: `/feature/crm` },
      { title: "Skip Tracing", link: `/feature/skip-tracing` },
      { title: "Tasks", link: `/feature/tasks` },
      { title: "Marketing", link: `/feature/marketing` },
      { title: "Analytics", link: `/feature/analytics` },
    ],
  }

  const learnLinks = {
    title: "Learn",
    links: [
      { title: "Academy", link: "https://academy.inveloapp.com/" },
      { title: "Masterclasses", link: "https://academy.inveloapp.com/bundles/masterclasses" },
      {
        title: (
          <span className={"coming-soon"}>
            {"Webinars"}
            <Tag color={"#E8567C"}>Coming Soon</Tag>
          </span>
        ),
        // link: "/",
      },
      { title: "Help Desk", link: "https://help.inveloapp.com/en/" },
      { title: "Community", link: "https://www.facebook.com/groups/inveloapp" },
      { title: "Demo", link: "/demo" },
    ],
  }

  const otherLinks = {
    title: "Other",
    links: [
      { title: "Website Terms of Use", link: "https://inveloapp.com/website-terms" },
      { title: "Customer Terms of Service", link: "https://inveloapp.com/customer-terms" },
      { title: "Marketing Terms of Service", link: "https://inveloapp.com/marketing-terms" },
      { title: "Privacy Policy", link: "https://inveloapp.com/privacy-policy" },
    ],
  }

  const links = [gettingStartedLinks, featuresLinks, learnLinks, otherLinks]
  const currentYear = new Date().getFullYear()

  const socialLinks = [
    { title: "youtubeSocial", link: "https://www.youtube.com/channel/UCNOvDUcDRhHLybYreFDR2eQ" },
    { title: "facebookSocial", link: "https://www.facebook.com/inveloapp" },
    { title: "twitterSocial", link: "https://twitter.com/inveloapp" },
    { title: "instaSocial", link: "https://www.instagram.com/inveloapp/" },
    { title: "linkedinSocial", link: "https://www.linkedin.com/company/inveloapp" },
  ]

  return (
    <>
      <FooterBar />
      <div className={"container"}>
        <Row className={"lists-container"} justify='space-between'>
          {links.map((item, indexList) => (
            <div className={`footer-list-item-${indexList}`} key={`footer-list - ${indexList}`}>
              <Space key={`space-list - ${indexList}`} direction={"vertical"} size={16}>
                <span className={"group-title"}>{item.title}</span>
                <List
                  className={"footer-navigation"}
                  bordered={false}
                  dataSource={item.links}
                  renderItem={(item, listElem) => (
                    <List.Item key={`${item.title}-${listElem}`} className={"navigation-item"}>
                      <a href={item.link}>{item.title}</a>
                    </List.Item>
                  )}
                />
              </Space>
            </div>
          ))}
        </Row>
        <hr className={"row-decor"} />
        <Row justify={"space-between"} wrap={false} align={"middle"} className={"footer-bottom"}>
          <span className={"footer-copyright"}>
            &copy;<span>{` Copyright ${currentYear} Invelo. All Rights Reserved`}</span>
          </span>
          <List
            className={"footer-social-links"}
            bordered={false}
            dataSource={socialLinks}
            renderItem={item => (
              <List.Item>
                <NavLink to={item.link} target='_blank' rel='noreferrer'>
                  <SvgIcon type={item.title} />
                </NavLink>
              </List.Item>
            )}
          />
        </Row>
      </div>
    </>
  )
}

export { Footer }
