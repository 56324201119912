import { useEffect } from "react"

const useHeaderPosition = () => {
  useEffect(() => {
    const header = document.querySelector<HTMLElement>(".ant-layout-header")
    const handleScrollHeader = () => {
      if (header) {
        if (window.scrollY < 200) {
          header.style.top = "0"
        }
      }
    }

    return () => {
      removeEventListener("scroll", handleScrollHeader)
    }
  }, [])
}

export { useHeaderPosition }
