import { FC } from "react"
import { useNavigate } from "react-router"
import { SvgIcon } from "../ui"

const Logo: FC<{ fill?: string }> = ({ fill = "white" }) => {
  const navigate = useNavigate()
  return <SvgIcon className={"logo-main"} fill={fill} type={"logoMain"} onClick={() => navigate("/")} />
}

export { Logo }
