import { Button, Row, Typography } from "antd"
import { FC } from "react"
import { Logo } from "../../logo"

const FooterBar: FC = () => {
  return (
    <Row wrap={false} align={"middle"} justify={"space-between"} className={"footer-bar"}>
      <Logo fill={"#6C23EE"} />
      <Typography.Title className={"title"} level={4}>
        {"Streamline your real estate investing now!"}
      </Typography.Title>
      <Button href={"https://app.inveloapp.com/register/"} className={"primary pink"}>
        {"Start Now!"}
      </Button>
    </Row>
  )
}

export { FooterBar }
