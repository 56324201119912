import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from "@apollo/client"
import { ConfigProvider } from "antd"
import { createContext, memo, useContext, type FC, type PropsWithChildren } from "react"
import { HelmetProvider } from "react-helmet-async"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { useToggle } from "react-use"
import introspection from "../../graphql"
import routes from "../../pages"
import theme from "../../themes"

type ContextProps = {
  burger: { opened: boolean; toggle: () => void }
}

const defaultValue: ContextProps = {
  burger: { opened: false, toggle: () => {} },
}

const Context = createContext(defaultValue)

const ContextProvider: FC<PropsWithChildren<ContextProps>> = ({ children }) => {
  const [opened, toggle] = useToggle(false)
  return <Context.Provider value={{ burger: { opened: opened, toggle: toggle } }}>{children}</Context.Provider>
}

const useApp: () => ContextProps = () => useContext(Context)

const client = new ApolloClient({
  link: createHttpLink({
    uri: `${import.meta.env.WEBSITE_API_URL ?? "/graphql"}`,
    credentials: "same-origin",
  }),
  connectToDevTools: import.meta.env.DEV,
  queryDeduplication: true,
  assumeImmutableResults: true,
  cache: new InMemoryCache({
    resultCaching: import.meta.env.PROD,
    possibleTypes: introspection.possibleTypes,
  }),
})

const router = createBrowserRouter(routes)

const App: FC = memo(() => {
  // const websiteName = "Invelo"

  return (
    <HelmetProvider>
      <ApolloProvider client={client}>
        <ContextProvider burger={defaultValue.burger}>
          <ConfigProvider theme={theme}>
            <RouterProvider router={router} />
          </ConfigProvider>
        </ContextProvider>
      </ApolloProvider>
    </HelmetProvider>
  )
})

export { useApp }

export default App
