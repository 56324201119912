import { FC, lazy } from "react"
import { Outlet, RouteObject } from "react-router-dom"

import { DefaultLayout, Loader } from "../components/layout"

const Home = lazy<FC>(() => import("./home"))
const About = lazy<FC>(() => import("./about"))
const Pricing = lazy<FC>(() => import("./pricing"))
const Demo = lazy<FC>(() => import("./demo"))
const Feature = lazy<FC>(() => import("./feature"))
const Comparison = lazy<FC>(() => import("./comparison"))
const routes: RouteObject[] = [
  {
    Component: DefaultLayout,
    loader: () => <Loader spinning />,
    children: [
      {
        Component: Home,
        path: "",
        index: true,
      },
      {
        Component: About,
        path: "/about",
      },
      {
        Component: Pricing,
        path: "/pricing",
      },
      {
        Component: Demo,
        path: "/demo",
      },
      {
        Component: Feature,
        path: "/feature/:featureName",
      },
      {
        Component: Comparison,
        path: "/comparison/:comparisonName",
      },
      {
        Component: Outlet,
        path: "*",
      },
    ],
  },
]

export default routes
