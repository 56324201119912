import { FC } from "react"
import { NavLink } from "react-router-dom"

import { Button, Row, Tag } from "antd"
import { SvgIcon } from "../ui"
import "./Menu.less"
import Navigation from "./Navigation"

const featureLinks = [
  {
    key: "list-builder",
    className: "background-purple",
    label: (
      <NavLink className='link-item' to={`/feature/list-builder`}>
        <SvgIcon type={"sidebar"} />
        <div className={"aside"}>
          <span className={"title"}>{"List Builder"}</span>
          <span className={"description"}>{"Create targeted prospect lists"}</span>
        </div>
      </NavLink>
    ),
  },
  {
    key: "crm",
    className: "background-rose",
    label: (
      <NavLink to={`/feature/crm`} className='link-item'>
        <SvgIcon type={"frame"} />
        <div className={"aside"}>
          <span className={"title"}>{"CRM"}</span>
          <p className={"description"}>{"Streamline seller relationship management"}</p>
        </div>
      </NavLink>
    ),
  },
  {
    key: "skip-tracing",
    className: "background-blue",
    label: (
      <NavLink className='link-item' to={`/feature/skip-tracing`}>
        <SvgIcon type={"chartSquare"} />
        <div className={"aside"}>
          <span className={"title"}>{"Skip tracing"}</span>
          <span className={"description"}>{"Find accurate contact information for property owners"}</span>
        </div>
      </NavLink>
    ),
  },
  {
    key: "marketing",
    className: "background-yellow",
    label: (
      <NavLink className='link-item' to={`/feature/marketing`}>
        <SvgIcon type={"arrowChart"} />
        <div className={"aside"}>
          <span className={"title"}>{"Marketing"}</span>
          <span className={"description"}>{"Automate effective marketing campaigns"}</span>
        </div>
      </NavLink>
    ),
  },
  {
    key: "tasks",
    className: "background-purple",
    label: (
      <NavLink className='link-item' to={`/feature/tasks`}>
        <SvgIcon type={"clipboard"} />
        <div className={"aside"}>
          <span className={"title"}>{"Tasks"}</span>
          <div className={"description"}>{"Manage business and team tasks"}</div>
        </div>
      </NavLink>
    ),
  },
  {
    key: "analytics",
    className: "background-rose",
    label: (
      <NavLink className='link-item' to={`/feature/analytics`}>
        <SvgIcon type={"chartCircle"} />
        <div className={"aside"}>
          <span className={"title"}>{"Analytics"}</span>
          <span className={"description"}>{"View actionable metrics for profitable decisions"}</span>
        </div>
      </NavLink>
    ),
  },
]
const learnLinks = [
  {
    key: "academy",
    className: "background-purple",
    label: (
      <NavLink className='link-item' to={`https://academy.inveloapp.com/`} target='_blank'>
        <SvgIcon type={"studentHat"} />
        <div className={"aside"}>
          <span className={"title"}>{"Academy"}</span>
          <span className={"description"}>{"Grow your business with comprehensive education"}</span>
        </div>
      </NavLink>
    ),
  },
  {
    key: "masterclasses",
    className: "background-rose",
    label: (
      <NavLink to={`https://academy.inveloapp.com/bundles/masterclasses`} className='link-item' target='_blank'>
        <SvgIcon type={"starIcon"} />
        <div className={"aside"}>
          <span className={"title"}>{"Masterclasses"}</span>
          <p className={"description"}>{"Learn from industry experts"}</p>
        </div>
      </NavLink>
    ),
  },
  {
    key: "webinars",
    className: "background-blue",
    label: (
      <NavLink className='link-item' to={`/`} target='_blank'>
        <SvgIcon type={"monitorIcon"} />
        <div className={"aside"}>
          <span className={"title"}>
            {"Webinars"}
            <Tag color={"#5BCADF"}>{"Coming Soon"}</Tag>{" "}
          </span>
          <span className={"description"}>{"Tune in to platform and strategy focused webinars"}</span>
        </div>
      </NavLink>
    ),
  },
  {
    key: "helpdesk",
    className: "background-yellow",
    label: (
      <NavLink className='link-item' to={`https://help.inveloapp.com/en/`} target='_blank'>
        <SvgIcon type={"askIcon"} />
        <div className={"aside"}>
          <span className={"title"}>{"Help Desk"}</span>
          <span className={"description"}>{"Access Invelo tutorials and support resources"}</span>
        </div>
      </NavLink>
    ),
  },
  {
    key: "community",
    className: "background-purple",
    label: (
      <NavLink className='link-item' to={`https://www.facebook.com/groups/inveloapp`} target='_blank'>
        <SvgIcon type={"messagesIcon"} />
        <div className={"aside"}>
          <span className={"title"}>{"Community"}</span>
          <div className={"description"}>{"Connect with like-minded professionals"}</div>
        </div>
      </NavLink>
    ),
  },
  {
    key: "demo",
    className: "background-rose",
    label: (
      <NavLink className='link-item' to={`/demo`}>
        <SvgIcon type={"playIcon"} />
        <div className={"aside"}>
          <span className={"title"}>{"Demo"}</span>
          <span className={"description"}>{"Experience Invelo in action"}</span>
        </div>
      </NavLink>
    ),
  },
  {
    key: "blog",
    className: "button",
    label: (
      <Row>
        <Button className={"button-latest"} href={`https://inveloapp.com/blog/`}>
          {"Latest from the blog"}
        </Button>
      </Row>
    ),
  },
]

function calculatePopupOffsetX() {
  const popupWidth = 864
  const windowWidth = window.innerWidth
  const breakpoints = [
    { width: 1200, offsetX: 175 },
    { width: 1300, offsetX: 120 },
    { width: 1400, offsetX: 80 },
    { width: 1500, offsetX: 30 },
    { width: 1600, offsetX: -30 },
    { width: 1700, offsetX: -50 },
    { width: 1800, offsetX: -90 },
    { width: 1900, offsetX: -130 },
    { width: 2000, offsetX: -160 },
  ]

  const { offsetX: defaultOffsetX } = breakpoints.find(({ width }) => windowWidth < width) || {
    offsetX: 0,
  }

  const offsetX = windowWidth / 2 - popupWidth / 2 + defaultOffsetX

  return offsetX
}

const generalNavigation = [
  {
    key: "/features",
    label: (
      <Row className={"main-menu-element"} wrap={false}>
        {"Features"} <SvgIcon type={"arrow"} className={"trigger-icon"} />
      </Row>
    ),
    popupClassName: "features-popup",
    popupOffset: [calculatePopupOffsetX() + 160, 40],
    children: featureLinks,
  },
  {
    key: "/learn",
    popupOffset: [calculatePopupOffsetX() + 40, 40],
    label: (
      <Row className={"main-menu-element"} wrap={false}>
        {"Learn"} <SvgIcon type={"arrow"} className={"trigger-icon"} />
      </Row>
    ),
    children: learnLinks,
    popupClassName: "learn-popup",
  },
  {
    key: "/pricing",
    label: (
      <NavLink className={"main-menu-element"} to={"/pricing"}>
        Pricing
      </NavLink>
    ),
  },
]

const HeaderMenu: FC = () => {
  return <Navigation mode={"horizontal"} triggerSubMenuAction={"click"} items={generalNavigation} />
}

export { HeaderMenu, generalNavigation }
