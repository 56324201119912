import { DevSupport } from "@react-buddy/ide-toolbox"
import { Layout } from "antd"
import classNames from "classnames"
import { FC, PropsWithChildren, Suspense } from "react"
import { Outlet } from "react-router-dom"
import { ComponentPreviews, useInitial } from "../../dev"
import { useScrollDirection } from "../../helpers/useScrollDirection"
import { HeaderMenu } from "../menu"
import { Footer } from "./Footer"
import { Header } from "./Header"
import "./Layout.less"
const DefaultLayout: FC = () => {
  const scrollDirection = useScrollDirection()

  return (
    <Layout>
      <Layout.Header className={classNames({ "header-open": scrollDirection === "up" })}>
        <Header renderMenu={HeaderMenu} />
      </Layout.Header>
      <Layout>
        <Suspense>
          <Outlet />
        </Suspense>
      </Layout>
      <Layout.Footer>
        <Footer />
      </Layout.Footer>
    </Layout>
  )
}

function withDevSupport<T extends PropsWithChildren = PropsWithChildren>(Wrapped: FC<T>): FC<T> {
  return (props: T) =>
    import.meta.env.PROD ? (
      <Wrapped {...props} />
    ) : (
      <DevSupport ComponentPreviews={ComponentPreviews} useInitialHook={useInitial}>
        <Wrapped {...props} />
      </DevSupport>
    )
}

export default withDevSupport(DefaultLayout)
